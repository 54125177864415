.checkbox {
    position: absolute;
    top: 50%;
    left: 10px; /* Adjust as needed */
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 1px solid #000; /* Checkbox border */
    background-color: #fff; /* Checkbox background color */
  }
  
  .checkbox.checked::after {
    content: "\u2713"; /* Unicode checkmark character */
    display: block;
    text-align: center;
    line-height: 15px;
    color: #000; /* Color of the checkmark */
  }
  