/* CSS */
.dropdown.input-group::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px; /* Adjust this value to position the arrow */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000; /* Adjust the color to match your design */
    pointer-events: none;
  }

  