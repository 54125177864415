.quantity-control {
    display: flex;
    align-items: center;
    border: solid 1px #dee2e6;
    height: 50px;
    border-radius: 0.375rem;
    padding: .375rem .75rem !important;
  }
  
  .quantity-control .btn {
    margin: 0 !important;
    color: hsl(0, 0%, 80%);
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .quantity-control .btn:hover {
    background-color: inherit; /* Prevent background color change on hover */
    color: #6c757d;
  }

  .quantity-control .quantity-input {
    text-align: center !important;
    flex: 1;
    margin: 0 5px;
    border-left: 0 !important;
    border-right: 0  !important;
  }
  

  .quantity-control .btn.btn-right {
    border-left: solid 1px hsl(0, 0%, 80%) !important;
}

.quantity-control .btn.btn-left {
  border-right: solid 1px hsl(0, 0%, 80%) !important;
}

/* Hide the spinner for number input fields */
.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

