/* BlueSection.css */

/* Define styles for the thin blue section */
.thin-blue-section {
    height: 10px; /* Adjust the height as needed */
    width: 100%;
    background-color: #2dccd3; /* Change to your desired blue color */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999; /* Adjust the z-index as needed */
    transition: transform 0.5s ease-in-out; /* Transition for smooth sliding with ease-in-out effect */
  }
  
  .thin-blue-section.scrolled-up {
    transform: translateY(-100%); /* Slide the blue section up */
  }