.receipt-options ol ul {
    margin-left: 0;
    margin-right: 0;
}



.causes-single-items.receipt-options .item ul li button {
    font-size: 13px;
    padding: 15px 0px;
    justify-content: center;
}