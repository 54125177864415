/* Footer.css */

.footer {
  background-color: rgba(239, 239, 239, 0.8); /* Adjust the alpha value as needed */

    color: white !important;
    padding: 2px 0;
    text-align: center;
  }
  
  .footer a {
    color: #54585a;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer a:hover {
    color: #2dccd3; /* Change to your desired hover color */
  }
  

  .footer-section {
    margin-bottom: 20px;
  }

  .footer h1, h2, h3, h4, h5, h6 {
    color: white;
  }

  .footer p {
    color: white;
  }